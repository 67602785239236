import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#31383F] text-gray-300">
      {/* Container */}
      <div className="max-w-[1000px] flex flex-col justify-center mx-auto px-8 text-gray h-full">
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <p className="text-green-400 py-3">Hey there glad to see you</p>
          <h1 className="text-4xl sm:text-7xl font-bold text-gray-300">
            Mehdi Ait Ouzdi
          </h1>
          <h2 className="text-xl sm:text-6xl font-bold max-w-[500px] text-gray-400">
            I'm a Full Stack Developer
          </h2>
          <p className="text-green-400 py-4 max-w-[500px]">
            I'm a full stack web developer driven by passion and looking for new
            opportunities, Currently focused on building accesible full stack
            web apps.
          </p>
          <div>
            <a href="https://drive.google.com/file/d/14pZQubB1_q4MvVDd0d-66thrV8bLbrZn/view?usp=drive_link">
            <button className="flex justify-center items-center border-2 px-6 py-4 my-2 hover:text-[#31383F] hover:bg-green-400 hover:border-green-400 duration-200">
              More Details
              <span className="pl-3">
                <FaArrowAltCircleRight />
              </span>
            </button>
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;
