import React from "react";
import {motion} from "framer-motion";

const Action = () => {
  return (
    <div
      name="action"
      className="w-full h-screen flex justify-center items-center p-4 bg-[#31383F] "
    >
      <motion.form
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        transition={{ duration: 0.5, delay: 0.5 }}
        method="POST"
        action="https://getform.io/f/71e1cf18-657a-4461-b186-0d253312966f"
        className="pt-16 max-w-[600px] flex flex-col w-full text-gray-300"
      >
        <div className="pb-2">
          <p className="text-4xl font-bold inline border-b-4 border-green-400">
            Action
          </p>
          <p className="py-4">
            Feel free to reach out with any inquiries or mail me{" "}
            <a
              href="mailto:elmahdi.aitouzdi@gmail.com"
              className="text-green-400 font-semibold"
            >
              elmahdi.aitouzdi@gmail.com
            </a>
            , and I'll make an effort to respond promptly.
          </p>
        </div>
        <input
          required
          className="text-gray-800 p-2 bg-[#d1ffd9f3]"
          type="text"
          name="name"
          placeholder="Name"
        />
        <input
          required
          className="text-gray-800 my-4 p-2 bg-[#d1ffd9f3]"
          type="email"
          name="email"
          placeholder="Email"
        />
        <textarea
          required
          placeholder="Message"
          name="message"
          className="text-gray-800 p-2 bg-[#d1ffd9f3]"
          rows="10"
        ></textarea>
        <button className="border-2 flex justify-center items-center px-6 py-3 w-fit mx-auto my-2 hover:bg-green-400 hover:border-green-400 hover:text-[#31383F] duration-300">
          Join Me
        </button>
      </motion.form>
    </div>
  );
};

export default Action;
