import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#31383F]">
      <div className="flex flex-col justify-center items-center w-full h-full text-gray-300">
        <div className="max-w-[1000px] w-full px-4 sm:grid grid-cols-2 gap-8">
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 1 }}
            className="sm:text-right pb-8 pl-4"
          >
            <p className="text-4xl font-bold inline-block border-b-4 border-green-400">
              About
            </p>
            <div className="max-w-[1000px] w-full py-4 gap-8">
              <p className="py-4">
                Hello, I'm Mehdi, a 20-year-old{" "}
                <span className="text-green-400 font-semibold">
                  web developer
                </span>{" "}
                based in Morocco who finds joy & entertainment in unraveling the
                complexities of the software development journey, constantly
                seeking new challenges to expand my knowledge and skills. In
                this fast-paced digital era, I am excited about the endless
                possibilities and the transformative impact that technology can
                have on our lives. Let's code and explore together as we
                navigate the exciting and limitless realms of software
                development!
              </p>
            </div>
          </motion.div>
          <div className="flex flex-col justify-center items-center">
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 10 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 1 }}
            >
              <img src="/profilePicture2.jpg" alt="" className="max-w-72 rounded" />
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
