import React from "react";
import { motion } from "framer-motion";

const Skills = () => {
  return (
    <div className="w-full h-screen bg-[#31383F]" name="skills">
      {/* Container */}
      <div className="max-w-[1000px] w-full h-full mx-auto px-4 text-gray-300 flex flex-col justify-center bg-[#31383F]">
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="pb-6"
        >
          <p className="text-4xl font-bold inline-block border-b-4 border-green-400">
            Skills
          </p>
          <p className="py-4">
            These are some technologies I used to work with recently :
          </p>
        </motion.div>

        <motion.div
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="grid grid-cols-2 sm:grid-cols-4 gap-4 bg-[#31383F]"
        >
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img className="w-20 mx-auto" src="/react.png" alt="react.png" />
            <p className="text-center py-2">React JS</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img
              className="w-20 mx-auto"
              src="/laravel.png"
              alt="laravel.png"
            />
            <p className="text-center py-2">Laravel</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img
              className="w-20 mx-auto"
              src="/firebase.png"
              alt="firebase.png"
            />
            <p className="text-center py-2">Firebase</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img className="w-20 mx-auto" src="/mongo.png" alt="mongo.png" />
            <p className="text-center py-2">Mongo DB</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img
              className="w-16 mx-auto"
              src="/javascript.png"
              alt="javascript.png"
            />
            <p className="text-center py-2">Javascript</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img className="w-20 mx-auto" src="/php.png" alt="php.png" />
            <p className="text-center py-2">PHP</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img
              className="w-20 mx-auto"
              src="/tailwind.png"
              alt="tailwind.png"
            />
            <p className="text-center py-2">Tailwind CSS</p>
          </div>
          <div className="flex flex-col cursor-pointer hover:scale-[1.1] duration-300 items-center justify-center shadow-md shadow-[#23282D] max-w-[300px] w-full">
            <img className="w-20 mx-auto" src="/mysql.png" alt="mysql.png" />
            <p className="text-center py-2">MySQL</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Skills;
