import React from 'react';
import { BsGithub } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";

const IconLinks = () => {
  return (
    <div className='xl:hidden w-full h-16 bg-[#31383F]'>
        <div className='max-w-[600px] h-full mx-auto flex flex-col justify-center items-center'>
            <ul className='flex text-gray-300'>
                <li className='hover:text-green-400'>
                    <a href="https://www.linkedin.com/in/el-mahdi-ait-ouzdi-89a527249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedin size={30}/></a>
                </li>
                <li className='hover:text-green-400'>
                    <a href="https://github.com/MehdiAIO?tab=overview&from=2024-01-01&to=2024-01-14"><BsGithub size={30}/></a>
                </li>
                <li className='hover:text-green-400'>
                    <a href="mailto:elmahdi.aitouzdi@gmail.com"><IoMail size={30}/></a>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default IconLinks