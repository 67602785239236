import { Helmet } from "react-helmet-async";
import About from "./Components/About";
import Action from "./Components/Action";
import Career from "./Components/Career";
import Home from "./Components/Home";
import IconLinks from "./Components/IconLinks";
import Navbar from "./Components/Navbar";
import ScrollBar from "./Components/ScrollBar";
import Skills from "./Components/Skills";

function App() {
  return (
    <>
      <Helmet>
        <title>mehdiaio</title>
        <meta
          name="description"
          content="Welcome to my portfolio website as a web developer, feel free to explore this 
          pure work of art that represents who I am and what I can do."
        />
        <link rel="canonical" href="https://mehdiaio.netlify.app"/>
      </Helmet>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Career />
      <Action />
      <IconLinks />
      <ScrollBar />
    </>
  );
}

export default App;
