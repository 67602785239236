import React from "react";
import { motion } from "framer-motion";
import { MdSchool } from "react-icons/md";

const Career = () => {
  return (
    <div
      name="career"
      className="flex items-center justify-center h-screen bg-[#31383F]"
    >
      <div className="flex flex-col max-w-[1000px] md:grid grid-cols-9 p-2 text-blue-50">
        <motion.div
          className="mb-8"
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <p className="text-4xl font-bold inline-block border-b-4 border-green-400 pt-4">
            Career
          </p>
        </motion.div>
        {/* left */}
        <div className="flex flex-row-reverse md:contents">
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="bg-green-800 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md"
          >
            <div className="flex flex-row items-center">
              <MdSchool size={30} className="mr-3" />
              <h3 className="font-semibold text-lg mb-1 text-green-50">
                ISTA NTIC Rabat 2022-2024
              </h3>
            </div>
            <p className="leading-tight text-justify text-green-50">
              Specialized technician degree (BAC+2) in digital development.
            </p>
          </motion.div>
          <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
            <div className="h-full w-6 flex items-center justify-center">
              <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
            </div>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-400 shadow"
            ></motion.div>
          </div>
        </div>
        {/* right */}
        <div className="flex md:contents">
          <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
            <div className="h-full w-6 flex items-center justify-center">
              <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
            </div>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-400 shadow"
            ></motion.div>
          </div>
          <motion.div
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="bg-green-800 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md"
          >
            <div className="flex flex-row items-center">
              <MdSchool size={30} className="mr-3" />
              <h3 className="font-semibold text-lg mb-1 text-green-50">
                Sciences University 2021-2022
              </h3>
            </div>
            <p className="leading-tight text-justify text-green-50">
              I have studied one year in physical and chemical sciences.
            </p>
          </motion.div>
        </div>
        {/* left */}
        <div className="flex flex-row-reverse md:contents">
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="bg-green-800 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md"
          >
            <div className="flex flex-row items-center">
              <MdSchool size={30} className="mr-3" />
              <h3 className="font-semibold text-lg mb-1 text-green-50">
                ElMehdi ElMenjra High School 2018-2021
              </h3>
            </div>
            <p className="leading-tight text-justify text-green-50">
              I achieved a baccalaureate degree in physical sciences in french.
            </p>
          </motion.div>
          <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
            <div className="h-full w-6 flex items-center justify-center">
              <div className="h-full w-1 bg-green-800 pointer-events-none"></div>
            </div>
            <motion.div
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-green-400 shadow"
            ></motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
