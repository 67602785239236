import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaBarsStaggered } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { BsGithub } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-scroll";

const Navbar = () => {
  const [hidden, setHidden] = useState(true);
  const handleClick = () => {
    setHidden(!hidden);
  };

  return (
    <div className="z-50 fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#31383F] text-gray-300">
      <motion.div
        initial={{ opacity:0 }}
        animate={{ opacity:1 }}
        transition={{ duration: "0.5" }}
        className="max-w-45"
      >
        <img
          className="w-full max-w-[170px] h-auto"
          src="/MehdiAIO.png"
          alt="logo.png"
        />
      </motion.div>

      <ul className="hidden md:flex">
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </motion.li>
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Link to="about" smooth={true} duration={500}>
            About
          </Link>
        </motion.li>
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Link to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </motion.li>
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Link to="career" smooth={true} duration={500}>
            Career
          </Link>
        </motion.li>
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Link to="action" smooth={true} duration={500}>
            Action
          </Link>
        </motion.li>
        {/* <li>About</li>
        <li>Skills</li>
        <li>Career</li>
        <li>Action</li> */}
      </ul>

      {/* Hamburger Icon */}
      <div className="md:hidden z-10" onClick={handleClick}>
        {hidden ? <FaBarsStaggered size={30} /> : <AiOutlineClose size={40} />}
      </div>

      {/* Mobile size */}
      <ul
        className={
          hidden
            ? "hidden"
            : "flex flex-col absolute top-0 right-0 w-full h-screen justify-center items-center bg-[#31383F]"
        }
      >
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="about" smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="career" smooth={true} duration={500}>
            Career
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link onClick={handleClick} to="action" smooth={true} duration={500}>
            Action
          </Link>
        </li>
      </ul>

      {/* IconsLink */}
      <ul className="hidden xl:flex fixed flex-col top-[37%] left-0">
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="w-[160px] h-[60px] flex items-center justify-center ml-[-100px] hover:ml-[-10px] duration-200 bg-black"
        >
          <a
            className="w-full flex justify-between items-center"
            href="https://github.com/MehdiAIO?tab=overview&from=2024-01-01&to=2024-01-14"
          >
            GitHub <BsGithub size={30} />
          </a>
        </motion.li>
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="w-[160px] h-[60px] flex items-center justify-center ml-[-100px] hover:ml-[-10px] duration-200 bg-red-600"
        >
          <a
            className="w-full flex justify-between items-center"
            href="mailto:elmahdi.aitouzdi@gmail.com"
          >
            Contact <IoMail size={30} />
          </a>
        </motion.li>
        <motion.li
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="w-[160px] h-[60px] flex items-center justify-center ml-[-100px] hover:ml-[-10px] duration-200 bg-blue-800"
        >
          <a
            className="w-full flex justify-between items-center"
            href="https://www.linkedin.com/in/el-mahdi-ait-ouzdi-89a527249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
          >
            LinkedIn <FaLinkedin size={30} />
          </a>
        </motion.li>
      </ul>
    </div>
  );
};

export default Navbar;
